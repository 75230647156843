import AnchorLink from "react-anchor-link-smooth-scroll";
import CardArnia from "./CardArnia";




export default function CardHive({ hive, setHives, active }) {


    return (
        
            <CardArnia
                hive={hive}
                setHives={setHives}
                sx={{
                    py: 5,
                    mb: 10,
                    boxShadow: 0,
                    textAlign: 'center',
                    '&:hover': { textDecoration: 'none' },
                    color: "#000",
                    bgcolor: active ? "#F2CF5D" : "#ffff66",

                }}
            />



    );

}