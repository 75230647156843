import { React, useState } from 'react';

import { Button, Col, Container, Row } from 'react-bootstrap';
import { ReactSession } from 'react-client-session';

// ----------------------------------------------------------------------

export default function Export2() {
  ReactSession.setStoreType("localStorage");
  const user = ReactSession.get("myuser");
  ReactSession.set("fixHive", 1);

  if (typeof user === "undefined") {
    window.location.href = "/login";
  }


  const [downloadApp, setDownloadApp] = useState(false)

  const handleDownload = async () => {
    try {
      setDownloadApp(true)
      const response = await fetch('https://s3-prima2020.s3.eu-west-3.amazonaws.com/SW/EXPORT-PRIMA.exe'); // Sostituisci 'URL_DEL_FILE' con il tuo URL
      const blob = await response.blob();

      // Creare un URL temporaneo per il download del file
      const url = URL.createObjectURL(blob);

      // Creare un link e cliccarlo per avviare il download del file
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export_PRIMA_v0.1.exe'); // Specificare il nome del file
      document.body.appendChild(link);
      link.click();

      // Pulire dopo il download
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error while downloading:', error);
    }
    finally {
      setDownloadApp(false)
    }

  }

  const handleTutorialDownload = async () => {
    try {
      const response = await fetch('https://s3-prima2020.s3.eu-west-3.amazonaws.com/SW/EXPORT-PRIMA.exe'); // Sostituisci 'URL_DEL_FILE' con il tuo URL
      const blob = await response.blob();

      // Creare un URL temporaneo per il download del file
      const url = URL.createObjectURL(blob);

      // Creare un link e cliccarlo per avviare il download del file
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'export_PRIMA_Tutorial_v0.1.pdf'); // Specificare il nome del file
      document.body.appendChild(link);
      link.click();

      // Pulire dopo il download
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error while downloading:', error);
    }
  }

  return (
    <Container>
      <Row className="mt-5">

        <Col className="text-center">
          <h2>Download Export App</h2>
          {downloadApp ?
            <Col className="text-center">
              Downloading...
            </Col> :
            <Button variant="light" onClick={handleDownload}>
              <img src="https://cdn.iconscout.com/icon/free/png-256/free-windows-221-1175066.png" alt="Windows Icon" width={100} height={100} />
              <br />
              Download
            </Button>
          }
        </Col>
      </Row>
      <hr className="my-5" />
      {/* <Row className="mb-5"> 
        <Col className="text-center">
          <h2>Tutorial</h2>
          <Button variant="light" onClick={handleTutorialDownload}>
            <img src="https://static.vecteezy.com/system/resources/thumbnails/010/929/566/small/video-tutorial-line-icon-free-vector.jpg" alt="Tutorial Icon" width={100} height={100} />
            <br />
            Download Tutorial
          </Button>
        </Col>
      </Row> */}
    </Container>
  );

}
