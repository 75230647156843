import * as React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { CircularProgress, Fab, Box, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom'
import { FileDownload } from '@mui/icons-material';


export default function Download() {
  ReactSession.setStoreType("localStorage");
  const user = ReactSession.get("myuser");


  const code = new URLSearchParams(window.location.search).get("code");


  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState("");
  const timer = React.useRef < Number > (0);
  const navigate = useNavigate()

  const iconSx = {
    ...({
      width: 150,
      height: 150
    }),
    ...(loading && {
      bgcolor: 'white',
      '&:hover': {
        bgcolor: 'white',
      }
    }),
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      }
    }),
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);



  // TODO: trasformare handleClick in redirectPage
  const HandleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);

      axios.post(`${process.env.REACT_APP_URLDB}/downloadzip`, { 'code': code }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        },
      }).then((response) => {
        if (response.status === 201) {
          setStatusMessage("The link has expired. Try to re-export.");
          setLoading(false);
        }
        else {
          // createFileDownload(response.data, 'PrimaData.zip');
          const a = document.createElement("a");
          a.href = response.data;
          a.click();
          setSuccess(true);
          setLoading(false);
          navigate('/dashboard/app');
        }

      });


      // timer.current = window.setTimeout(() => {
      //   setSuccess(true);
      //   setLoading(false);
      //   navigate('/dashboard/app')
      // }, 2000);
    }

  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-30%, -50%)' }}>
        <Box sx={{ m: 1, position: 'relative' }}>
          <Fab
            aria-label="loading"
            color="primary"
            sx={iconSx}
            onClick={HandleButtonClick}
          >
            {loading ? <Box component="img" src="https://www.svgrepo.com/show/258301/bee.svg" sx={{ width: 40, height: 40 }} /> : <FileDownload sx={{ width: 50, height: 50 }} />}
          </Fab>
          {loading && (
            <CircularProgress
              size={165}
              sx={{
                color: '#FFCC99',
                position: 'absolute',
                top: -6,
                left: -6,
                zIndex: 1,
              }}
            />
          )}
        </Box>
      </Box>
      <Typography variant="h3" component="h3" sx={{ display: 'flex', alignItems: 'center', position: 'absolute', left: '50%', top: '70%', transform: 'translate(-30%, -50%)' }}>
        {loading ? "loading . . . \n(It can take time, you can use other tabs meanwhile, please don't close this)" : "Download file"}
      </Typography>

      <div className="alert alert-warning bg-soft-primary border-0" role="alert" hidden={statusMessage === ""}>
        {statusMessage}
      </div>

    </>
  );
}


function createFileDownload(content, fileName) {
  const url = window.URL.createObjectURL(new Blob([content]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); // or any other extension
  document.body.appendChild(link);
  link.click();
}