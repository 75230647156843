import { IconButton } from "@mui/material";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Upload } from '@mui/icons-material';
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import { Button, Col, Container, Modal, ProgressBar, Row } from "react-bootstrap";
import React, { useState } from "react";
import { ReactSession } from 'react-client-session';
import S3 from 'react-aws-s3-typescript';

/* eslint-disable global-require */
window.Buffer = window.Buffer || require("buffer").Buffer;
/* eslint-enable global-require */


export default function CardDevice({ currentHive, device, setOccupedDevices, setDevices, setCurrentDevice, currentDevice, setHiveDevices, setBeeFilesElab, setDates }) {
    ReactSession.setStoreType("localStorage");
    const user = ReactSession.get("myuser");
    const [uploaded, setUploaded] = useState(0);
    const [totalUploaded, setTotalUploaded] = useState(0);
    const [progressBarUpload, setProgressBarUpload] = useState(0);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [uploadCompleted, setUploadCompleted] = useState(false);


    let countFile = 0;
    let progress = 0.001;
    let id;


    const ReactS3Client = new S3({
        bucketName: 's3-prima2020',
        dirName: 'BeeFiles', 
        region: 'eu-west-3',
        accessKeyId: 'AKIASWP4E6OP5ULMQU6N',
        secretAccessKey: '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',
    });

    const hiddenFileInput = React.useRef(null);

    function updateDataHistoryDevice() {
        setCurrentDevice(device);

        if (parseInt(device.type, 10) === 2) {
            axios.post(`${process.env.REACT_APP_URLDB}/beefilesavailable`, { 'id_device': device.id_device }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                if (response.data.error.code === 1) {
                    console.log('error');
                }


                // const sortedDates = response.data.payload.sort((a, b) => a.date_start.localeCompare(b.date_start));
                // const result = [];
                // let start = sortedDates[0].date_start;
                // let end = sortedDates[0].date_end;

                // for (let i = 1; i < sortedDates.length; i+=1) {
                //   const current = sortedDates[i];
                //   const next = sortedDates[i + 1];

                //   if (next && next.date_start === current.date_end) {
                //     // Estendi l'intervallo corrente
                //     end = next.date_end;
                //     console.log('estendi')
                //   } else {
                //     // Crea un nuovo intervallo
                //     result.push({ start, end });
                //     start = current.date_start;
                //     end = current.date_end;
                //     console.log('nuovo')
                //   }
                // }

                // // Aggiungi l'ultimo intervallo alla lista risultante
                // result.push({ start, end });

                // console.log(result)

                let dateFiles = [];
                let date = "";
                let timeStart = "";
                let timeEnd = "";

                response.data.payload.map(d => {
                    if (date === "" || dateFormat(d.date_start, 'mm/dd/yyyy') > date || dateFormat(d.date_start, 'HH:MM') !== timeEnd) {
                        if (date !== "") {
                            dateFiles = [...dateFiles, { 'date': date, 'time_start': timeStart, 'time_end': timeEnd }];
                        }
                        date = dateFormat(d.date_start, 'mm/dd/yyyy');
                        timeStart = dateFormat(d.date_start, 'HH:MM');
                    }

                    timeEnd = dateFormat(d.date_end, 'HH:MM');
                    return true;

                });

                dateFiles = [...dateFiles, { 'date': date, 'time_start': timeStart, 'time_end': timeEnd }];
                // console.log(dateFiles);

                setBeeFilesElab(dateFiles);
                setDates(new Set(dateFiles.map(df => df.date)));

            }).catch(e => toast.update(id, { render: `Error with connection! (${e})`, type: "error", isLoading: false, autoClose: 2000 }));

        }
    }



    const unlinkDevice = () => {
        const id = toast.loading(`Unlinking  ${device.Nickname}`);
        axios.post(`${process.env.REACT_APP_URLDB}/unlinkdevice`, { 'id_device': device.id_device, 'id_hive':currentHive.id_hive, 'date_end': dateFormat(new Date(), "yyyy/mm/dd") }, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            if (response.data.error.code === 1) {
                toast.update(id, { render: "Error!", type: "error", isLoading: false, autoClose: 2000 });
            }
            else {
                setDevices(oldArray => oldArray.filter((d) => d.id_device !== device.id_device));
                setOccupedDevices(oldArray => oldArray.filter((d) => d.id_device !== device.id_device));
                setHiveDevices(oldArray => oldArray.filter((d) => d.id_device !== device.id_device));
                toast.update(id, {
                    render: "Done!", type: "success", isLoading: false, autoClose: 2000,
                });

                setCurrentDevice([{ "id_device": 0, "name": "" }]);
            }
        }); // .catch(toast.update(id, { render: "Error with connection!", type: "error", isLoading: false }));

    }




    const handleUpload = () => {
        hiddenFileInput.current.click();
    }

    const handleFile = async event => {
        countFile = 0;
        setUploaded(0);
        progress = 0.001;
        setProgressBarUpload(progress);
        // id = toast(`Upload in progress: ${countFile}/${event.target.files.length} files`, { progress });
        setTotalUploaded(event.target.files.length);
        uploadFiles(id, event.target.files, event.target.files.length);

    }

    return (
        <tr style={{ borderColor: currentDevice.id_device === device.id_device ? 'black' : 'inherit' }} onClick={() => updateDataHistoryDevice()} >
            <td>{device.id_device}</td>
            <td>{device.Nickname}</td>
            {user.type_user === 0 ? (<Container hidden />) :
                (<td>
                    <IconButton aria-label="unlink device" color="secondary" onClick={unlinkDevice}>
                        <LinkOffIcon />
                    </IconButton>
                    {device.type === '2' ?
                        <><IconButton aria-label="upload file" color="primary" sx={{ ml: 15 }} onClick={handleUpload}>
                            <Upload />
                        </IconButton><div hidden>
                                <input type="file" name="files[]" ref={hiddenFileInput} onChange={handleFile} accept=".bee" multiple />
                            </div></> : <Container />}

                </td>)}

            <Modal
                show={showUploadModal}
                dialogClassName="modal-90w"
                aria-labelledby="contained-modal-title-vcenter"
                style={{ position: 'relative' }}
            >
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                File(s) uploaded: {uploaded}/{totalUploaded} files
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ProgressBar now={progressBarUpload * 100} variant='warning' />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }} hidden={!uploadCompleted}>
                            <Col />
                            <Col>
                                Upload completed. <Button variant="outline-success" size="sm" onClick={() => setShowUploadModal(false)}>Close</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </tr>




    );





    function uploadFiles(id, files, length) {

        const dateFile = dateFormat(new Date(), "yyyy_mm_dd_HH:MM:ss", { gmt: true })

        if (length > 0) {
            setUploadCompleted(false)
            setShowUploadModal(true)
        }

        ReactS3Client
            .uploadFile(files[countFile], `${device.id_device}_${dateFile}_${files[countFile].name}`)
            .then(data => {
                axios.post(`${process.env.REACT_APP_URLDB}/upload-file`, { 'id_device': device.id_device, 'filename': files[countFile].name, 'date_send': dateFile, 'id_hive':currentHive.id_hive }, {
                    headers: {
                        'Authorization': `Bearer ${user.token}`,
                    },
                }).then((response) => {
                    countFile += 1
                    setUploaded(countFile)
                    progress = (countFile / length)
                    setProgressBarUpload(progress)
                    if (countFile < length) {
                        uploadFiles(id, files, length)
                    }
                    else {
                        setUploadCompleted(true);
                    }
                })
            })
            .catch(err => console.error(err))



    }


}

